var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userAccessData && _vm.headers)?_c('div',[_c('div',{staticClass:"my-3"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.display.newAdmin = true}}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v(" 新規管理者 ")],1),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"success"},on:{"click":function($event){return _vm.saveSettings()}}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" 保存 ")],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.display.newAdmin),callback:function ($$v) {_vm.$set(_vm.display, "newAdmin", $$v)},expression:"display.newAdmin"}},[_c('v-sheet',{staticClass:"pa-3"},[_c('div',[_vm._l((_vm.bulkAdminForm),function(user,index){return _c('v-form',{key:index,model:{value:(_vm.bulkAdminForm[index].valid),callback:function ($$v) {_vm.$set(_vm.bulkAdminForm[index], "valid", $$v)},expression:"bulkAdminForm[index].valid"}},[(_vm.bulkAdminForm.length > 1)?_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(_vm._s(index+1)+")")]),_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.popElement(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e(),_c('v-text-field',{attrs:{"label":"名前","outlined":"","dense":"","rules":[
                            function (v) { return (v || '').length >= 2 && (v || '').length <= 20 || '2文字以上かつ20文字以下を入力してください'; } 
                        ]},model:{value:(_vm.bulkAdminForm[index].name),callback:function ($$v) {_vm.$set(_vm.bulkAdminForm[index], "name", $$v)},expression:"bulkAdminForm[index].name"}}),_c('v-text-field',{attrs:{"label":"メール(ログインID)","outlined":"","dense":"","type":"email","rules":[
                            function (v) { return _vm.validateEmail(v) || '有効なメール形式ではありません'; } 
                        ]},model:{value:(_vm.bulkAdminForm[index].email),callback:function ($$v) {_vm.$set(_vm.bulkAdminForm[index], "email", $$v)},expression:"bulkAdminForm[index].email"}}),_c('v-text-field',{attrs:{"label":"パスワード","outlined":"","dense":"","rules":[
                            function (v) { return (v || '').length >= 8 || '最小８文字入れてください'; } 
                        ]},model:{value:(_vm.bulkAdminForm[index].password),callback:function ($$v) {_vm.$set(_vm.bulkAdminForm[index], "password", $$v)},expression:"bulkAdminForm[index].password"}})],1)}),_c('v-btn',{attrs:{"color":"warning","width":"100%"},on:{"click":_vm.addOneFormSlot}},[_vm._v("もう一人追加")]),_c('v-btn',{attrs:{"disabled":!_vm.checkAllForm,"width":"100%","color":"success"},on:{"click":_vm.addAdmin}},[_vm._v("登録")])],2)])],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.userAccessData,"items-per-page":5,"footer-props":{
            showFirstLastPage: true,
            'items-per-page-text': '表示数',
            'items-per-page-options': [10, 50, -1],
        }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',_vm._l((_vm.headers),function(header,index){return _c('td',{key:index,staticClass:"pa-3 text-center"},[(header.value==='uid.name')?[_vm._v(" "+_vm._s(item.uid.name)+" ")]:(header.value==='remove')?[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeAdmin(item.uid._id)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]:[_c('v-simple-checkbox',{attrs:{"ripple":false},model:{value:(item.accessible[header.value.split('.')[1]]),callback:function ($$v) {_vm.$set(item.accessible, header.value.split('.')[1], $$v)},expression:"item.accessible[header.value.split('.')[1]]"}})]],2)}),0)]}}],null,false,3343884001)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }