<template>
    <div v-if="userAccessData && headers">
        <div class="my-3">
            <v-btn 
                color="primary" 
                @click="display.newAdmin = true"
            >
                <v-icon>mdi-plus-thick</v-icon>
                新規管理者
            </v-btn>
            <v-btn 
                color="success" 
                class="mx-3"
                @click="saveSettings()"
            >
                <v-icon>mdi-content-save</v-icon>
                保存
            </v-btn>
        </div>
        <v-dialog v-model="display.newAdmin" max-width="500px">
            <v-sheet class="pa-3">
                <div>
                    <v-form  v-model="bulkAdminForm[index].valid" v-for="(user,index) in bulkAdminForm" :key="index"> 
                        <div class="d-flex" v-if="bulkAdminForm.length > 1">
                            <div>{{index+1}})</div>
                            <v-btn @click="popElement(index)" color="error" icon>
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                        <v-text-field
                            label="名前"
                            outlined dense
                            :rules="[
                                v => (v || '').length >= 2 && (v || '').length <= 20 || '2文字以上かつ20文字以下を入力してください' 
                            ]"
                            v-model="bulkAdminForm[index].name"
                        >
                        </v-text-field>
                        <v-text-field
                            label="メール(ログインID)"
                            outlined dense
                            type="email"
                            :rules="[
                                v => validateEmail(v) || '有効なメール形式ではありません' 
                            ]" 
                            v-model="bulkAdminForm[index].email"
                        >
                        </v-text-field>
                        <v-text-field
                            label="パスワード"
                            outlined dense
                            v-model="bulkAdminForm[index].password"
                            :rules="[
                                v => (v || '').length >= 8 || '最小８文字入れてください' 
                            ]"
                        >
                        </v-text-field>        
                    </v-form>
                    <v-btn color="warning" width="100%" @click="addOneFormSlot">もう一人追加</v-btn>
                    <v-btn :disabled="!checkAllForm"  width="100%" color="success" @click="addAdmin">登録</v-btn>
                </div>
            </v-sheet>
        </v-dialog>
        <v-card>
            <v-data-table
                :headers="headers"
                :items="userAccessData"
                :items-per-page="5"
                class="elevation-1"
                :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': '表示数',
                'items-per-page-options': [10, 50, -1],
            }"
            >
                <template
                    v-slot:item="{ item }"
                >
                    <tr>
                       <td 
                            class="pa-3 text-center"
                            v-for="(header, index) in headers"
                            :key="index"
                        >
                            <template v-if="header.value==='uid.name'">
                                {{item.uid.name}}
                            </template>
                            <template v-else-if="header.value==='remove'">
                                <v-btn color="error" @click="removeAdmin(item.uid._id)">
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>                
                            <template v-else>
                                <v-simple-checkbox 
                                    :ripple="false"
                                    v-model="item.accessible[header.value.split('.')[1]]"
                                >
                                </v-simple-checkbox>
                            </template>
                        </td>
                    </tr>
                </template> 
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import UserList from '../components/DataTables/UserList.vue'
export default {
    components:{
        UserList
    },
    data:()=>({
        headers:null,
        userAccessData:null,
        display:{
            newAdmin:false
        },
        selectedUsers:null,
        bulkAdminForm:[
            {
                name:"",
                valid:false,
                password:"",
                email:""
            }
        ],
    }),
    mounted(){
        this.getAccessInfo()
        this.generateHeaders()
    },
    computed:{
        AccessMap(){
            return this.$store.state.accessMap
        },
        checkAllForm(){
              for(let i = 0; i<this.bulkAdminForm.length ; i++) {
                    let form = this.bulkAdminForm[i]
                    if(form.valid === false){
                        return false
                    } 
                }
                return true
        }
    },
    methods:{
        popElement(index){
            this.bulkAdminForm.splice(index, 1)
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        async saveSettings(){
            await this.$store.dispatch('post', {url:"/api/saveAccessInfo", showUI:true, rawData:this.userAccessData})
        },
        async getAccessInfo(){
            let result = await this.$store.dispatch('get', {url:"/api/getAccessInfo", showUI:true})
            if(result)
                this.userAccessData = result
        },
        addOneFormSlot(){
            this.bulkAdminForm.push({
                name:"",
                valid:false,
                password:"",
                email:""
            })
        },
        async addAdmin(){
            await this.$store.dispatch('post', {url:"/api/addAdmin", showUI:true, rawData:this.bulkAdminForm})
            this.display.newAdmin = false
            this.getAccessInfo()
        },
        async removeAdmin(targetId){
            await this.$store.dispatch('post', {url:"/api/removeAdmin", showUI:true, rawData:{
                targetId
            }})
            this.getAccessInfo()
        },
        generateHeaders(){
            const defaultHeaders = [
                {text: "削除", value:"remove"},
                {text: "名前", value:"uid.name" },
            ]
            let reconstructedMenu = defaultHeaders
            const AccessMap = this.$store.state.accessMap
            for(let key in AccessMap){
                reconstructedMenu.push({
                    text:AccessMap[key],
                    value:"accessible."+key
                })
            }
            this.headers = reconstructedMenu
        },

    }
}
</script>
